@import "bs-addition";
@import "bs-overwrite";

.btn-beweship {
  color: #fff;
  background-color: #213581;
  border-color: #213581;
}
.btn-outline-beweship {
  color: #213581;
  border-color: #213581;
}

.centered-label {
  display: flex;
  align-items: center;
}

.custom-img {
  object-fit: contain;
  height: 185px !important;
  position: relative !important;
  width: unset !important;
  max-width: 320px !important;
}

.shopping-cart-image {
  object-fit: contain !important;
  position: relative !important;
  height: 70px;
  width: 70px;
}

.cursor-pointer {
  cursor: pointer;
}

.unset-img {
  height: 185px;
}
.unset-img > div {
  position: unset !important;
}
.visuallyHidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1;
  margin: -1;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 20;
  width: 1;
}

.img-modal-body {
  height: 87vh;
}

.card-img {
  max-height: 400px;
}

.modal-90w {
  width: 90vw;
}
