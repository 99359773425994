.d-grid {
  display: grid !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-30px {
  padding-left: 30px;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}
.ml-4 {
  margin-left: 2rem !important;
}
